<template>
  <div class="placeholder">
    <b-overlay :show="showOverlay" rounded="sm">
      <form id="form">
        <p></p>
      </form>
      <template #overlay>
        <div class="text-center">
          <b-spinner label="Loading..."></b-spinner>
          <p id="cancel-label">
            We are processing your payment, please wait...
          </p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  name: 'process',
  data() {
    return {
      paidWater: null,
      paidBond: null,
      paidMaintenance: null,
      paidRent: null,
      ewayAccessCode: null,
      payer: null,
      paidToDate: null,
      showOverlay: true,
    };
  },
  mounted() {
    this.ewayAccessCode = new URLSearchParams(window.location.search).get(
      'AccessCode'
    );

    if (localStorage.paidWater) {
      this.paidWater = localStorage.paidWater;
    }
    if (localStorage.rentUpToCalculated) {
      this.paidToDate = localStorage.rentUpToCalculated;
    }
    if (localStorage.paidBond) {
      this.paidBond = localStorage.paidBond;
    }
    if (localStorage.paidMaintenance) {
      this.paidMaintenance = localStorage.paidMaintenance;
    }
    if (localStorage.paidRent) {
      this.paidRent = localStorage.paidRent;
    }
    if (localStorage.payer) {
      this.payer = localStorage.payer;
    }
    const balanceData = {
      paidWater: this.paidWater,
      paidBond: this.paidBond,
      paidRent: this.paidRent,
      paidMaintenance: this.paidMaintenance,
      EWAY_ACCESSCODE: this.ewayAccessCode,
      payer: this.payer,
      paidToDate: this.paidToDate,
    };
    axios
      .post(
        `/api/update?code=${process.env.VUE_APP_API_KEY_UPDATE}`,
        balanceData
      )
      .then(() => {
        this.$router.push({
          name: 'receipt',
        });
      })
      .catch((error) => {
        if (error.response.status === 404) {
          this.errorMessage = 'Payment record not found';
        } else if (error.response.status === 400) {
          this.errorMessage =
            'Your query can not processed. Invalid or missing parameters.';
        } else if (error.response.status === 401) {
          this.errorMessage = 'Unauthorized request. Your session is expired.';
        } else if (error.response.status === 409) {
          this.errorMessage = 'Balances dont match the payment amount.';
        } else if (error.response.status === 422) {
          this.errorMessage = 'Payment has been declined.';
        } else {
          this.errorMessage = 'Your query can not be processed. Server error.';
        }
        this.$router.push({
          name: 'payments',
          params: {
            errorCode: error.response.status,
            processError: this.errorMessage,
          },
        });
      });
  },
};
</script>
